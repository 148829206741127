import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ValueProps from "../components/Repeating/ValueProps";
import Testimonials from "../components/Repeating/Testimonials";
import Resources from "../components/Repeating/Resources";
import RecentBlogPosts from "../components/Repeating/RecentBlogPosts";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

const Page = ({ data }) => {
  const faqs = [
    {
      question: "WHAT IS A JUMBO LOAN?",
      answer: (
        <>
          <p>
            A jumbo loan can help you purchase a home that exceeds the
            conforming loan limits set by Fannie Mae and Freddie Mac. As of
            2022, the limit is $647,200 for most of the U.S., apart from Alaska,
            Hawaii, Guam, and the U.S. Virgin Islands, where the limit is
            $970,800.
          </p>
          <p className="mb-0">
            A jumbo loan can be used to purchase a primary residence, an
            investment property, or a vacation home. Rates tend to be a bit
            higher because lenders generally have a higher risk.
          </p>
        </>
      ),
    },
    {
      question: "WHY CONSIDER A JUMBO LOAN?",
      answer: (
        <>
          <p className="mb-0">
            A jumbo loan can help you purchase a home that exceeds conforming
            loan limits, such as those in a highly competitive market, or in a
            desirable area.
          </p>
          <p className="mb-0">
            Financing is available for up to $2.5 million and jumbo loans offer
            the convenience of one loan vs. multiple loans.
          </p>
        </>
      ),
    },
    {
      question: "HOW DO I QUALIFY FOR A JUMBO LOAN?",
      answer: (
        <>
          <p>
            To qualify, you’ll need to be a high earner, have a great credit
            score, and have a low debt-to-income ratio.
          </p>
        </>
      ),
    },
    {
      question:
        "WHAT’S THE DIFFERENCE BETWEEN A CONFORMING LOAN AND A JUMBO LOAN?",
      answer: (
        <>
          <p>
            A conforming loan meets the loan limit set by Fannie Mae and Freddie
            Mac, which is $647,200 for most of the U.S. (the limit is $970,800
            in Alaska, Hawaii, Guam, and the U.S. Virgin Islands).
          </p>
          <p className="mb-0">
            A jumbo loan exceeds those limits and is therefore considered
            non-conforming.
          </p>
        </>
      ),
    },
    {
      question: "HOW DO JUMBO LOAN RATES COMPARE TO CONFORMING RATES?",
      answer: (
        <>
          <p>
            Because lenders are taking on a bigger risk, you can expect a higher
            interest rate. It ultimately depends on the market conditions, plus
            your lender.
          </p>
        </>
      ),
    },
    {
      question: "HOW DO I GET A CERTIFICATE OF ELIGIBILITY?",
      answer: (
        <>
          <p>
            Visit the U.S. Department of Veterans Affairs Certificate of
            Eligibility page for instructions on requesting a COE.
          </p>
        </>
      ),
    },
    {
      question: "CAN MY LENDER GET MY CERTIFICATE OF ELIGIBILITY FOR ME?",
      answer: (
        <>
          <p>
            Yes, you can ask your lender to obtain your Certificate of
            Eligibility on your behalf.
          </p>
        </>
      ),
    },
    {
      question: "WHAT ARE THE LIMITS OF A JUMBO LOAN?",
      answer: (
        <>
          <p>You can finance up to $2.5 million, as long as you qualify.</p>
        </>
      ),
    },
    {
      question: "HOW DOES A JUMBO LOAN WORK?",
      answer: (
        <>
          <p>Here’s how the process works:</p>
          <ul className="list-disc pl-6">
            <li>Complete our simple Jumbo Loan Qualifier</li>
            <li>
              We’ll give you loan options based on your criteria and scenario
            </li>
            <li>We’ll help you compare mortgage interest rates and terms</li>
            <li>You’ll choose the offer that best fits your needs</li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Jumbo Loans | Home Loans | Accel Mortgage Brokers"
        description="Is your dream home more expensive than what conventional conforming loan limits allow? The jumbo loan gives you an opportunity to buy the home you really want."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 md:mb-24 md:pt-6">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h1>Jumbo Loan: Get a Bigger, Better House</h1>
              <p>
                Is the home you’re dreaming of more expensive than what
                conventional conforming loan limits allow? Enter the jumbo loan,
                which allows you to buy the California home you really want.
              </p>
              <div className="flex flex-wrap items-center space-y-6 md:flex-nowrap md:space-y-0 md:space-x-4">
                <ButtonSolid
                  href="/request-rates/"
                  text="Get a Rate Quote"
                  className="w-full md:w-auto"
                />
                <ButtonSolid
                  href="https://markdarling.floify.com/apply-now"
                  text="Get Pre-Approved"
                  outboundLink={true}
                  altStyle={2}
                  className="w-full md:w-auto"
                />
              </div>
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../images/8.0 Jumbo Loans/1.0 Hero.jpg"
                loading="eager"
                width={560}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mb-16 md:mb-20">
            <h2>The Benefits of a Jumbo Home Loan</h2>
          </header>
          <div className="grid gap-x-10 gap-y-16 md:grid-cols-2 lg:gap-x-20">
            <div className="flex space-x-5">
              <div className="number-text-stroke">1</div>
              <div>
                <h3 className="heading-four mb-2">More Options</h3>
                <p className="mb-0">
                  You can buy a bigger home, a luxury home, or purchase one in a
                  more desirable market.
                </p>
              </div>
            </div>

            <div className="flex space-x-5">
              <div className="number-text-stroke">2</div>
              <div>
                <h3 className="heading-four mb-2">Flexible Loan Terms</h3>
                <p className="mb-0">
                  Jumbo loans come in all shapes and sizes with rates and terms
                  tailored to match your goals.
                </p>
              </div>
            </div>

            <div className="flex space-x-5">
              <div className="number-text-stroke">3</div>
              <div>
                <h3 className="heading-four mb-2">Flexible Down Payment</h3>
                <p className="mb-0">
                  Minimum required down payments range from 5% to 20%, depending
                  on the lender.
                </p>
              </div>
            </div>

            <div className="flex space-x-5">
              <div className="number-text-stroke">4</div>
              <div>
                <h3 className="heading-four mb-2">
                  Competitive Interest Rates
                </h3>
                <p className="mb-0">
                  Lenders are seeing lower risk with jumbo loans and are
                  offering better interest rates.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h2>Who’s Eligible?</h2>
              <p>
                You’ll need to be in good financial health to qualify for a
                jumbo mortgage. Lenders will look for:
              </p>
              <ul className="styled-list-arrow">
                <li>A high credit score (typically 680 or above)</li>
                <li>A low debt-to-income ratio</li>
                <li>Cash reserves in the bank</li>
                <li>Documentation showing you’re in good financial health</li>
              </ul>
              <div className="flex flex-wrap items-center space-y-6 md:flex-nowrap md:space-y-0 md:space-x-4">
                <ButtonSolid
                  href="/request-rates/"
                  text="Get a Rate Quote"
                  className="w-full md:w-auto"
                />
                <ButtonSolid
                  href="https://markdarling.floify.com/apply-now"
                  text="Get Pre-Approved"
                  outboundLink={true}
                  altStyle={2}
                  className="w-full md:w-auto"
                />
              </div>
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../images/8.0 Jumbo Loans/Jumbo.jpg"
                loading="lazy"
                width={560}
                className="z-0 rounded-6xl"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mb-8 md:mb-10">
            <h2>Frequently Asked Questions</h2>
          </header>
          <Accordion
            allowZeroExpanded={true}
            className="border-t border-gray-700/20"
          >
            {faqs.map((faq, i) => {
              return (
                <div key={i}>
                  <AccordionItem
                    uuid={i}
                    className="border-b border-gray-700/20 py-6 md:py-8"
                  >
                    <AccordionItemButton className="flex justify-between space-x-10 focus:outline-none md:space-x-20">
                      <AccordionItemState>
                        {(state) => {
                          return (
                            <>
                              <p className="heading-five mb-0">
                                {faq.question}
                              </p>

                              <i
                                className={`far ${
                                  state.expanded
                                    ? "fa-chevron-up"
                                    : "fa-chevron-down"
                                } text-base text-gray-700/50`}
                              ></i>
                            </>
                          );
                        }}
                      </AccordionItemState>
                    </AccordionItemButton>
                    <AccordionItemPanel className="pt-6 pr-10 md:pr-20">
                      {faq.answer}
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              );
            })}
          </Accordion>
        </div>
      </section>

      <Resources />
      <Testimonials />
      <ValueProps />
      <RecentBlogPosts />
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
